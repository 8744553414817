var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.editorData
        ? _c("v-skeleton-loader", { attrs: { type: "article@4" } })
        : _c("froala-view", {
            model: {
              value: _vm.editorData,
              callback: function($$v) {
                _vm.editorData = $$v
              },
              expression: "editorData"
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }