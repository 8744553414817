<template>
  <div>
    <v-skeleton-loader
      v-if="!editorData"
      type="article@4"
    />
    <froala-view
      v-else
      v-model="editorData"
    />
  </div>
</template>

<script>
import '@/plugins/vue-froala-wysiwyg'
import { azBlobService } from '@/shared/services'

export default {

  name: 'DisplayReleaseNotes',

  props: {
    showLatest: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    editorData: null
  }),

  created () {
    this.loadReleaseNotes()
  },

  methods: {
    loadReleaseNotes () {
      azBlobService.getReleaseNotes().then(resp => {
        if (resp.errorResponse) {
          this.$store.commit('setGlobalSnackbar', {
            message: 'Could not get latest release notes',
            color: 'error'
          })
        } else {
          if (this.showLatest) {
            this.formatReleases([resp[0]])
          } else {
            this.formatReleases(resp)
          }
        }
      })
    },
    formatReleases (resp) {
      var _this = this
      this.editorData = resp.reduce(function (str, elem) {
        return str + `<h2>${elem.version} - ${_this.$options.filters.displayDateFormat(elem.releaseDate)}</h2>
        <br />
          <ul>
            ${elem.bullets.reduce((str2, val) => {
    return str2 + `<li>${val}</li>`
  }, '')}
          </ul>
          <br />
          <br />`
      }, '')
    }
  }
}
</script>

<style lang="scss" scoped>
.ck-editor__editable_inline {
  max-height: 200px;
}
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border: none !important;
}
.ck.ck-reset_all {
  height: 0% !important;
}
</style>
